<template>
  <div class="p-4 text-sm">
    <template v-if="isLoading">
      <PLoader />
    </template>
    <template v-else>
      <h2 class="font-semibold text-lg bg-gray-100">
        {{ $t("Common.OrderStatus.Sent") }}
        <span class="text-xs font-normal">({{ $t("Common.General.ClickToEdit")}})</span>
      </h2>
      <div v-if="sentOrders.length > 0" class="mt-2 flex flex-col space-y-2">
        <PalletReceiptsLogItem
          :order="order"
          v-for="(order, index) in sentOrders"
          :key="index"
          :clickable="true"
          @click="onClick"
        ></PalletReceiptsLogItem>
      </div>
      <div class="mt-1 italic" v-else>
        {{ $t("Common.General.NoOrders") }}.
      </div>
      <h2 class="mt-4 font-semibold text-lg bg-gray-100">
        {{ $t("Common.OrderStatus.Confirmed") }}
      </h2>
      <div
        v-if="confirmedOrders.length > 0"
        class="mt-2 flex flex-col space-y-2"
      >
        <PalletReceiptsLogItem
          :order="order"
          v-for="(order, index) in confirmedOrders"
          :key="index"
        ></PalletReceiptsLogItem>
      </div>
      <div class="mt-1 italic" v-else>
        {{ $t("Common.General.NoOrders") }}.
      </div>
    </template>
  </div>
</template>
<script>
import api from "@/api/orders";
import { mapGetters } from "vuex";
import PalletReceiptsLogItem from "./PalletReceiptsLogItem.vue";
export default {
  components: {
    PalletReceiptsLogItem,
  },
  props: {
    inv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      hasLoaded: false,
      orders: [],
      orderByField: "dateSend",
      orderByDescending: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(["customer", "location"]),
    sentOrders() {
      return this.orders.filter((order) => order.transactionStatusId === 4);
    },
    confirmedOrders() {
      return this.orders.filter((order) => order.transactionStatusId === 5);
    },
  },
  methods: {
    onClick(order) {
      this.$router.push({ name: "palletReceipts", params: { id: order.id } });
    },
  },
  async created() {
    this.isLoading = true;
    
    let args = {
      customerId: this.customer.id,        
      transactionType: "OO,GL",
    }

    if (this.inv) {
      args.locationIdTo = this.location.id
    } else {
      args.locationIdFrom = this.location.id
    }
    
    this.orders = await api.getOrders(
      "orders",
      args,
      this.orderByField,
      this.orderByDescending,
      this.filters,
      10
    );
    this.isLoading = false;
    this.hasLoaded = true;
  },
};
</script>
