<template>
  <div
    class="bg-gradient-to-b from-gray-50 to-gray-200 border-gray-300 py-2 px-3 border rounded-md"
    :class="{
      'cursor-pointer hover:from-green-100 hover:to-green-100 hover:border-green-500': clickable,
    }"
    @click="onClick"
  >
    <div class="flex items-center justify-between">
      <div>
        <h2 class="font-semibold text-base">
          {{ order.id }} <span>{{ order.sendersRef }}</span>
        </h2>
      </div>
      <div>
        {{ $t(`Common.OrderStatus.${order.transactionStatus}`) }}
      </div>
    </div>
    <table class="text-xs">
      <tr>
        <td class="pr-2">{{ $t("Common.OrderStatus.Sent") }}</td>
        <td>{{ order.createdLocal | dateAndTime }}</td>
      </tr>
      <tr v-if="order.dateConfirmed">
        <td class="pr-2">{{ $t("Common.OrderStatus.Confirmed") }}</td>
        <td>
          {{ order.dateConfirmed | dateAndTime }}
        </td>
      </tr>
      <tr>
        <td class="pr-2">{{ $t("Common.General.Location") }}</td>
        <td>{{ order.locationNameTo }}</td>
      </tr>
      <tr>
        <td class="pr-2">{{ $t("Common.General.Product") }}</td>
        <td>
          <span v-for="(t, index) in order.transactions" :key="index">
            {{ t.quantityOrdered }}x {{ t.productName }}
            <span v-if="index < order.transactions.length - 1">, </span></span
          >
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: ["order", "clickable"],
  methods: {
    onClick() {
      if (this.clickable) {
        this.$emit("click", this.order);
      }
    },
  },
};
</script>
